<template>
  <c-auto-complete
    v-model="selectedObject"
    :auto-select-one-item="false"
    :component-item="$props.template"
    :get-label="$props.getLabel"
    :input-attrs="{ class: 'form-control' }"
    :items="$props.options"
    :min-len="1"
    :placeholder="$props.placeholder"
    :disabled="$props.disabled"
    @change="updateSearch"
  />
</template>

<script>
import "vue-search-select/dist/VueSearchSelect.css";
import CAutoComplete from "@/components/input/CAutocomplete.vue";
import ItemNameTemplate from "@/components/input/CAutoCompleteTemplates/ItemNameTemplate.vue";
import { useDebounce } from "@vueuse/core";
import { ref } from "@vue/composition-api";

export default {
  name: "PaginatedSearchSelect",
  components: { CAutoComplete },
  props: {
    selected: {
      type: Object,
      default: () => null
    },
    options: {
      type: Array,
      required: true,
    },
    getLabel: {
      type: Function,
      required: true,
    },
    template: {
      type: Object,
      default: () => ItemNameTemplate,
    },
    placeholder: {
      type: String,
      default: "Search..."
    },
    disabled: {
      type: Boolean,
      default: false
    },
    debounceInterval: {
      type: Number,
      default: 250
    }
  },
  data() {
    const search = ref("")
    return {
      selectedObject: null,
      search: search,
      debouncedSearch: useDebounce(search, this.$props.debounceInterval),
    }
  },
  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedObject = this.$props.selected
      }
    },
    selectedObject() {
      if (!this.selectedObject) {
        this.search.value = "";
      }

      this.$emit("change", this.selectedObject);
    },
    "debouncedSearch.value"() {
      this.$emit("change-search", this.debouncedSearch.value);
    }
  },
  methods: {
    updateSearch(search) {
      this.search.value = search
    },
  }
};
</script>

<style scoped lang="scss"></style>
